import * as THREE from 'three'
import { Canvas, useLoader } from '@react-three/fiber'
import { MeshTransmissionMaterial, useGLTF, AccumulativeShadows, RandomizedLight, Environment, OrbitControls, Center } from '@react-three/drei'

export default function App() {
  return (
    <Canvas shadows camera={{ position: [0, 0, 4], fov: 25 }}>
      <ambientLight intensity={Math.PI} />
      <group position={[0, -2.5, 0]}>
        <Center top>
          <IceCube />
        </Center>
        {/* <AccumulativeShadows temporal frames={100} alphaTest={0.9} color="#818e96" colorBlend={1} opacity={0.8} scale={20}>
          <RandomizedLight radius={10} ambient={0.5} intensity={Math.PI} position={[2.5, 8, -2.5]} bias={0.001} />
        </AccumulativeShadows> */}
      </group>
      <OrbitControls 
        minPolarAngle={Math.PI / 2.2} 
        maxPolarAngle={Math.PI / 2.2} 
        minZoom={100} 
        maxZoom={100} 
        autoRotate 
        autoRotateSpeed={0.5}  
        makeDefault 
        enableZoom={false} 
        enablePan={false}  
      />
      {/* <Environment files="/dancing_hall_1k.hdr"  /> */}
    </Canvas>
  )
}

export function IceCube() {
  const { nodes: iceCubeNodes, materials: iceCubeMaterials } = useGLTF('/ice_cube.glb')
  const { nodes: nycNodes, materials: nycMaterials } = useGLTF('/nyc.glb')
  
  const frostTexture = useLoader(THREE.TextureLoader, '/frost_inverted.png')
  frostTexture.repeat.set(120, 120)
  frostTexture.wrapS = THREE.RepeatWrapping
  frostTexture.wrapT = THREE.RepeatWrapping

  const cubePosition = [0, -0.2, 0]
  const cubeColor = '#ffffff'

  nycMaterials.nyc.onBeforeCompile = (shader) => {
    shader.fragmentShader = shader.fragmentShader.replace(
      '#include <dithering_fragment>',
      `
        #include <dithering_fragment>
        // Convert final color to grayscale using luminance weights
        gl_FragColor.rgb = vec3(dot(gl_FragColor.rgb, vec3(0.299, 0.587, 0.114)));
      `
    );
  };

  return (
    <group dispose={null}>
      <mesh 
        geometry={iceCubeNodes.cube.geometry} 
        position={cubePosition}
        scale={2.5}
        >
        <MeshTransmissionMaterial 
          background={new THREE.Color("#222222")} 
          backside={false}
          samples={5}
          resolution={2048}
          transmission={1}
          thickness={0.07}
          ior={1.2}
          chromaticAberration={0.2}
          anisotropy={0}
          distortion={0.0}
          distortionScale={0.3}
          temporalDistortion={0.5}
          // clearcoat={0.1}
          attenuationDistance={0.5}
          attenuationColor={'#dddddd'}
          color={cubeColor}
          bg={cubeColor}
          roughnessMap={frostTexture}
          roughness={1}
        />
      </mesh>
      {/* <mesh
        castShadow
        renderOrder={-100}
        geometry={iceCubeNodes.cube_inverted.geometry}
        material-side={THREE.FrontSide}
        position={cubePosition}
        scale={2.5}
      /> */}
      <group scale={0.47} position={[-0.1, 0.2, -0.6]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nycNodes.Object_10.geometry} material={nycMaterials.nyc} />
      </group>
    </group>
  )
}
